 /* .markdown pre {
    white-space: pre;
   font-size: 12px;
    line-height: 16px; 
    overflow-x: auto;  Enable horizontal scrolling 
    font-family: monospace;
    tab-size: 4;  Adjust the desired tab size 
    -moz-tab-size: 4;  For Firefox support 
    background-color: antiquewhite;
    border-radius: 15px;
  }*/

  /* @media (max-width: 650px) {
    .markdown pre {
      font-size: 12px;
      line-height: 16px;
    }
  } */

  

  /* @media (max-width: 650px) {
    .code-syntax-highliter {
      font-size: 1px;
      line-height: 14px;
    }
  } */

  .tabbed-textarea {
    font-family: monospace;
    tab-size: 4; /* Adjust the desired tab size */
    -moz-tab-size: 4; /* For Firefox support */
  }



