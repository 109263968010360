
.gradient-back-container {
    background: linear-gradient(to bottom, #ffe5bc, #fff6e4, #fff7e8, #ffffff );
    height: 100vh;
    width:100%;
    display:flex;
    align-items: center;
    justify-content:center;
    /* font-weight:bolder; */
    font-size: 23px;
    /* border: solid green 2px; */
}

.identity {
    /* border: solid red 1px; */
    margin-left: 5px;
    white-space: pre; /* Preserve white spaces as they are */
    width: 206px;
    font-weight: bolder;
}


@media (max-width: 650px) {
    .gradient-back-container {
        font-size: 19px;
    }
    .identity {
        width: 170px;
    }
}
