.timeline-container {
    width: 100%;
    max-width: 1200px;
    /* border: solid greenyellow 2px; */
    margin-top: 100px;
    position: relative;
}

.top-section-container {
    /* border: solid red 1px; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}


.nav-arrow {
    border: solid #dfdfdf 1px;
    background-color: #f5f5f5;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.5s ease;
    /* margin: 100px; */
}

.nav-arrow:hover {
    background-color: #c8c7c7;
}

.content-display {
    /* border: solid red 1px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    padding: 5px 10px;
    width: 700px;
    height: 290px;
}

#content-title {
    font-weight: bolder;
    font-size: 22px;
}

#content-desc {
    text-align: center;
}

.content-icon-img {
    /* border: solid red 1px; */
    width: 74px;
    height: 74px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
}

.bottom-section-navbar {
    /* border: solid green 2px; */
    /* margin: 2px; */
    display: flex;
    overflow-x: hidden; /* Allow for horizontal scrolling */
    white-space: nowrap;
    scroll-behavior: smooth;
    padding-left: calc(50% - 15px); /* Half of container width minus half of item width */
    padding-right: calc(50% - 15px); /* Same for the right */
    /* padding-left: 540px;
    padding-right: 540px; */
}

.straight-line {
    border: solid 2px #dfdfdf;
    position: absolute;
    border-radius: 5px;
    width: 100%;
    bottom: 35px;
    z-index: -1;
}

.timeline-item-container {
    margin: 0px 20px;
    /* border: solid red 1px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

.timeline-item {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: solid #dfdfdf 1px;
    background-color: #f5f5f5;
    cursor: pointer;
    transition: background-color 0.4s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
}

.timeline-item.active {
    background-color: #a7a6a6;
    cursor: default;
}

.timeline-year.active {
    font-weight: bold;
    color: grey;
}

.timeline-item:hover {
    background-color: #a7a6a6;
}

@media (max-width: 880px) {
    .nav-arrow {
        display: none;
    }
    #content-title{
        font-size: 17px;
        /* border: solid red 1px; */
        margin: 5px 5px 10px 5px;
    } 
}