
/* This way,after adding .onlyForCommentsExcludeCategory,the category won't be affected by this global class */
.onlyForCommentsExcludeCategory .MuiTreeItem-content {
    /* color: red; */
    /* background-color: lightpink; */
    border-radius: 10px;
    padding-top : 7px;
    padding-bottom : 8px;
    padding-left : 0px;
}

@media (max-width: 650px) {
  .onlyForCommentsExcludeCategory .MuiTreeItem-content {
        /* color: red; */
        /* background-color: lightpink; */
        border-radius: 10px;
        padding-top : 0px;
        padding-bottom : 8px;
        padding-left : 0px;
    }
}

@media (max-width: 650px) {
    /* Apply the style to .MuiTreeItem-group only on devices with a screen width of 600px or less (mobile devices) */
    .onlyForCommentsExcludeCategory .MuiTreeItem-group {
      margin-left: 0px;
    }
  }

  @media (max-width: 650px) {
    .onlyForCommentsExcludeCategory .MuiTreeItem-iconContainer {
        color:red;
        /* border: solid 1px black; */
        margin-left: -12px;
        margin-right: 0px;
        padding: 0px;
      }
  }