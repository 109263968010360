

.opensource-project-container {
    max-width: 1200px;
    /* border: solid pink 2px; */
    margin: 100px 10px 0px 10px;
    /* height: 400px; */
}

.opensource-project-title {
    font-size: 23px;
    /* border: solid red 1px; */
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.opensource-project-item-card {
    border: solid #dfdfdf 1px;
    background-color: #f5f5f5;
}

.opensource-project-card-title {
    font-weight: bolder;
    margin: 15px;

}

.opensource-project-card-desc {
    color: #596881;
    margin: 0px 15px;
}

.opensource-project-card-githublink {
    margin: 0px 15px;
}

.opensource-project-card-githublink a {
    color: #1876e5;
    margin-right: 10px;
}

.opensource-project-card-language-container {
    display: flex;
    /* border: solid red 1px; */
}

.opensource-project-card-language {
    display: flex;
    /* border: solid blue 1px; */
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.opensource-project-card-language-colorbar {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 5px;
}

