


.life-outside-coding-container {
    max-width: 1200px;
    /* border: solid pink 2px; */
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}


.skill-icon-container {
    /* border: solid red 1px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.skill-icon-container-img {
    /* border: solid red 1px; */
    border-radius: 50%;
    margin-right: 10px;
    width: 48px;
    height: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}


.outside-coding-container {
    display: flex;
    align-items: center;
    justify-content: center;

}




.outside-coding-thing {
    border: solid #dfdfdf 1px;
    background-color: #f5f5f5;
    border-radius: 5px;
    /* margin: 8px; */
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mini-cover-img {
    /* border: solid red 1px; */
    width: 130px;
    height: 130px;
    border-radius: 50%;
    overflow: hidden;
}

.skill-sets-container {
    /* border: solid red 1px; */
    width: 100%;
    max-width: 1200px;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
}

.skill-sets-desc-container {
    display: flex;
    /* border: solid burlywood 2px; */
    /* margin: 1px; */
    width: 100%;
}

.skill-sets-desc {
    border: solid #dfdfdf 1px;
    /* border: solid red 1px; */
    background-color: #f5f5f5; 
    border-radius: 5px;
    /* margin: 8px; */
    padding-bottom: 20px;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skill-sets-piechart {
    border: solid #dfdfdf 1px;
    background-color: #f5f5f5;
    margin: 8px;
    border-radius: 5px;
    flex-shrink: 0;
    width: 600px;
    height: 300px;
}

.footer {
    background-color: #f5f5f5;
    /* border: solid red 1px; */
    border-top: solid 1px #cecece;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.footer span {
    /* border: solid red 1px; */
    /* display: flex; */
    width: 22px;
    height: 22px;
    margin: 6px;
    background-color: #f5f5f5;
    cursor: pointer;
    background-image: url('./red-heart.png');
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-tap-highlight-color: transparent;
}






@media (max-width: 900px) {
    .skill-sets-piechart {
        width: 100%;
        height: 240px;
        margin: 0px;
    }
    .skill-sets-container {
        flex-direction: column;

    }
    /* .skill-sets-desc {
        margin: 8px;
    } */
    .mini-cover-img {
        width: 100px;
        height: 100px;
    }

}


@media (max-width: 700px){
    .life-outside-coding-container {
        flex-direction: column;

    }
    .mini-cover-img {
        width: 100px;
        height: 100px;
    }
}


