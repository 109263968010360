/* div {
    border : solid green 2px;
} */

.link {
    text-decoration-color:#50b8f1;
    color:black;
    
}
.link:hover {
    text-decoration-color:#50b8f1;
    color:#50b8f1;
}

/* .paragraph {
    margin: 40px;
} */

.coverImg {
    width: 100%;
}

.title {
    font-weight: bold;
    /* font-size: 48px; */
    text-align: center;
    margin:5px;
    /* margin-top: 50px; */
    /* line-height: 60px; */
}

.subTitle {
    color: #999999;
    font-size: 17px;
    text-align: center;
    margin: 5px;
}

.styledUL {
    margin: 40px;
}

.highlighter {
    font-size: 20px;
}

/* @media (max-width: 800px) {
    .article {
        font-size: 10px;
    }
} */

.inlinecode {
    background-color: #e3e8f4;
    border-radius : 8px;
}

.notes {
    background-color: #d4efee;
    border-radius: 10px;
}
