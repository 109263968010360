
.headerSectionName {
    color: #281a2c;
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    margin: auto 5px;
    font-size: 21px;
    white-space: nowrap; /*Prevents text from wrapping to the next line */
}



.headerSectionName .underline {
    background-color: #292221;
    height: 2px;
    border-radius: 3px;
    display: none;
}

.headerSectionName.active .underline {
    display: block;
}

.headerSectionName.active {
    cursor: default;
}




.headerAuthorName {
    color: #281a2c;
    padding: 3px 5px;
    border-radius: 5px;
    cursor: pointer;
    margin: auto 0px;
    font-size: 25px;
    font-weight: bolder;
    
    white-space: nowrap; /*Prevents text from wrapping to the next line */

}




@media (max-width: 650px) {
    .headerSectionName {
        font-size: 18px;
    }
    .headerAuthorName {
        font-size: 18px;
    }
}

